import React from "react";
import type { AppProps } from "next/app";
import { CssBaseline } from "@mui/material";
import { Layout } from "components/Layout";
import { Provider } from "react-redux";
import { wrapper } from "store";
import { RootProvider } from "../context";
import SuperTokens from "supertokens-web-js";
import { getConfig } from "config";
import { appWithTranslation } from "next-i18next";
// import Script from "next/script";
import "styles/root.css";
import "styles/notion.css";
import "katex/dist/katex.min.css";
import "prismjs/themes/prism-tomorrow.css";

if (typeof window !== "undefined") {
  SuperTokens.init(getConfig());
}
function MyApp({ Component, ...rest }: AppProps) {
  // const [mounted, setMounted] = React.useState(false);
  const {
    store,
    props: { pageProps },
  } = wrapper.useWrappedStore(rest);
  // necessary for server-side rendering
  // because mode is undefined on the server
  // React.useEffect(() => {
  //   setMounted(true);
  // }, []);
  // if (!mounted) {
  //   return null;
  // }
  return (
    <Provider store={store}>
      <RootProvider>
        <CssBaseline />
        <Layout>
          <Component {...pageProps} />
        </Layout>
        {/* <Script
          src="https://code.jquery.com/jquery-3.2.1.min.js"
          integrity="sha256-hwg4gsxgFZhOsEEamdOYGBf13FyQuiTwlAQgxVSNgt4="
          crossOrigin="anonymous"
        />
        <Script
          type="text/javascript"
          src="https://cdn.iamport.kr/js/iamport.payment-1.2.0.js"
        /> */}
      </RootProvider>
    </Provider>
  );
}

export default appWithTranslation(MyApp);
