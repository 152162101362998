import { AppBar, AppBarProps } from "@mui/material";

interface HomeAppBarProps extends AppBarProps {
  children: React.ReactNode;
}

export function HomeAppBar(props: HomeAppBarProps) {
  const { children, ...restProps } = props;
  return <AppBar {...restProps}>{children}</AppBar>;
}
