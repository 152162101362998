import React from "react";
import { Footer } from "./Footer";
import { useSnackbar } from "notistack";
import { useRouter } from "next/router";
import { useAppSelector } from "store";

export const FooterContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { authenticated } = useAppSelector((state) => state.auth);
  const handleClick = (
    type:
      | "type1"
      | "type2"
      | "homepage"
      | "youtube"
      | "copy"
      | "instagram"
      | "notification"
      | "notice"
      | "inquiry"
      | "naver",
  ) => {
    switch (type) {
      case "type1":
        router.push("/content/43226ec11ece414b92b12e3aac8e51ef");
        break;
      case "type2":
        router.push("/content/f985d33aa4dd41989725a602c517b978");
        break;
      case "homepage":
        window.open("http://chang-soft.com/");
        break;
      case "youtube":
        window.open("https://www.youtube.com/@builderhub");
        break;
      case "copy":
        navigator.clipboard.writeText(process.env.NEXT_PUBLIC_HOST_URL);
        getSnackbar("주소가 복사되었습니다.", "success");
        break;
      case "instagram":
        window.open("https://www.instagram.com/builderhub_official/");
        break;
      case "notification":
        window.open("/content/30d30eb37e2043b28ba8ebcd37612362");
        break;
      case "notice":
        router.push("/help/notice");
        break;
      case "inquiry":
        if (authenticated) router.push("/help/inquiry");
        else {
          router.push(
            `${process.env.NEXT_PUBLIC_AUTH_URL}/signin?forward=${process.env.NEXT_PUBLIC_HOST_URL}/help/inquiry`,
          );
        }
        break;
      case "naver":
        window.open("https://blog.naver.com/builderhub");
        break;
    }
  };
  const getSnackbar = React.useCallback(
    (errorString: string, variant: "success" | "warning" | "error") => {
      enqueueSnackbar(`${errorString}`, {
        variant: variant,
        autoHideDuration: 2500,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    },
    [enqueueSnackbar],
  );
  return <Footer handleClick={handleClick} />;
};
