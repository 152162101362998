import moment from "moment";
import type {} from "iamport-typings";
type Response = {
  error_code: number | null;
  error_msg: string | null;
  imp_uid: string;
  merchant_uid: string;
  pg_provider: string;
  pg_type: string;
  success: boolean;
};
// type Callback = (rsp: Response) => void;

// export function getIdentity(callback: Callback) {
//   const merchant_uid = `bhplf-${moment().format("YYYYMMDDHHmmssSSS")}`;
//   // @ts-ignore
//   window.IMP?.certification(
//     {
//       merchant_uid,
//       m_redirect_url: process.env.REACT_APP_IDENTITY_REDIRECT_URL,
//       // process.env.REACT_APP_ENV === "development"
//       //   ? "https://customer.dev.builderhub.io/signup/identity"
//       //   : process.env.REACT_APP_ENV === "production"
//       //   ? "https://customer.builderhub.io/signup/identity"
//       //   : "http://localhost:3000/signup/identity",
//       popup: false,
//     },
//     callback,
//   );
// }

export async function getIdentityAsync(
  redirectUrl = "/signup/identity",
): Promise<Response> {
  const merchant_uid = `bhplf-${moment().format("YYYYMMDDHHmmssSSS")}`;
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    IMP?.certification(
      {
        merchant_uid,
        m_redirect_url: `${process.env.NEXT_PUBLIC_HOST_URL}${redirectUrl}`,
      },
      function (rsp: Response) {
        if (rsp.success) {
          return resolve(rsp);
        }
        return reject(rsp);
      },
    );
  });
}
