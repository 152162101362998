import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { getApiDomain } from "context";
import SessionWebJs from "supertokens-web-js/recipe/session";
import {
  AppInfoUserInput,
  SuperTokensConfig,
} from "supertokens-web-js/lib/build/types";

const appInfo: AppInfoUserInput = {
  appName: "builderhub-home",
  apiDomain: getApiDomain(),
  apiBasePath: "/auth",
};

export const getConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      ThirdPartyEmailPassword.init(),
      SessionWebJs.init({
        tokenTransferMethod: "cookie",
        sessionTokenBackendDomain:
          process.env.NODE_ENV === "production"
            ? ".builderhub.io"
            : process.env.NEXT_PUBLIC_TUNNEL === "true"
              ? ".builderhub.io"
              : "localhost",
        sessionTokenFrontendDomain:
          process.env.NODE_ENV === "production"
            ? ".builderhub.io"
            : process.env.NEXT_PUBLIC_TUNNEL === "true"
              ? ".builderhub.io"
              : "localhost",
      }),
    ],
  };
};
