import { configureStore, Middleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import { authReducer } from "./features";
export * from "./features";
import { createWrapper } from "next-redux-wrapper";

const customMiddleware: Middleware = () => (next) => (action) => {
  return next(action);
};

const middlewares = (): Middleware[] => {
  if (process.env.NODE_ENV !== "production") {
    return [logger, customMiddleware];
  } else {
    return [customMiddleware];
  }
};

/* Creating a store with the reducer and middleware. */
const makeStore = () => {
  const store = configureStore({
    reducer: {
      auth: authReducer,
      // [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middlewares()),
    devTools: process.env.NODE_ENV !== "production",
  });
  return store;
};

export const store = makeStore();

export const wrapper = createWrapper(makeStore);
export type RootState = ReturnType<ReturnType<typeof makeStore>["getState"]>;

export type AppDispatch = ReturnType<typeof makeStore>["dispatch"];

export type AppStore = ReturnType<typeof makeStore>;

/* Exporting the useDispatch hook from react-redux and giving it a type. */
export const useAppDispatch: () => AppDispatch = useDispatch;
/* Exporting the useSelector hook from react-redux and giving it a type. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
