import React from "react";
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  HttpLink,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { signOutThunk, store } from "store";

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Not authorized user") {
        store.dispatch(signOutThunk());
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function getApiDomain() {
  const apiUrl = process.env.NEXT_PUBLIC_API_URL || "http://localhost:8000";
  return apiUrl;
}

export function getWebsiteDomain() {
  const websiteUrl =
    process.env.NEXT_PUBLIC_HOST_URL || `http://localhost:9999`;
  return websiteUrl;
}

const httpLink = new HttpLink({
  uri: getApiDomain(),
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(errorLink, httpLink),
});

export function ApolloClientProvider(props: { children: React.ReactNode }) {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApolloClientProvider;
