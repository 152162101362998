import React from "react";
import {
  Wrapper,
  EndingDiv,
  TextBox,
  MainTypo,
  SubTypo,
  ImageDiv,
  EndingButton,
} from "./styles";
import Image from "next/image";
import { Link } from "components";
import { useTranslation } from "next-i18next";

export const Ending = () => {
  const { t } = useTranslation("common");
  return (
    <Wrapper data-aos="fade-up">
      <EndingDiv>
        <TextBox>
          <MainTypo>{t("easyConstruction")}</MainTypo>
          <SubTypo>{t("startWith")}</SubTypo>
        </TextBox>
        <ImageDiv>
          <Image
            src="/HomeImages/Couple.png"
            alt="Couple"
            width={604}
            height={461}
          />
        </ImageDiv>
        <Link href={process.env.NEXT_PUBLIC_CUSTOMER_URL} replace>
          <EndingButton>{t("startNow")}</EndingButton>
        </Link>
      </EndingDiv>
    </Wrapper>
  );
};
