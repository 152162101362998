import Session from "supertokens-web-js/recipe/session";
// import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { useAppDispatch, setMyInfo, setAuth } from "store";
import { useGetMyInfoQuery } from "generated/types";
import { getMyInfoData } from "./Types";
import { createContext, useContext, useEffect } from "react";
import { useRouter } from "next/router";

// export const signInClicked =
//   (thirdPartyId: ThirdPartyId, redirectUrl: string) => async () => {
//     try {
//       const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
//         thirdPartyId,
//         frontendRedirectURI: `${process.env.NEXT_PUBLIC_HOST_URL}/auth/callback/${thirdPartyId}?forward=${redirectUrl}`,
//       });
//       window.location.assign(authUrl);
//     } catch (err) {
//       console.log(err);
//     }
//   };

const Context = createContext({});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  useGetMyInfoQuery({
    onCompleted(data) {
      dispatch(setAuth({ authenticated: true }));
      dispatch(setMyInfo(getMyInfoData(data)));
    },
  });
  useEffect(() => {
    if (router.asPath.includes("/content")) return;
    Session.doesSessionExist().then((exist) => {
      if (
        !exist
        // &&
        // router.pathname !== "/signup" &&
        // router.pathname !== "/findPassword" &&
        // router.pathname !== "/notFound" &&
        // router.pathname !== "/reset-password" &&
        // !router.pathname.startsWith("/auth") &&
        // !router.pathname.startsWith("/share")
      ) {
        // router.replace(forward);
      }
    });
  }, [dispatch, router.pathname, router]);
  return <Context.Provider value={{}}>{children}</Context.Provider>;
}

export const useSessionAuth = () => useContext(Context);

export default AuthProvider;
