import React from "react";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { ClearIcon, ExitIcon, PersonIcon, HiddenAnchor } from "components";
import { CursorDiv, FlexDiv } from "../styles";
import {
  DrawerWrapper,
  DrawerHeader,
  DrawerList,
  DrawerFooter,
} from "./styles";
import { PropType } from "./types";

export const CustomDrawer = (props: PropType) => {
  const {
    authenticated,
    open,
    goSignIn,
    handleDrawerToggle,
    goCustomer,
    goMyPage,
    goSignOut,
    goPartner,
    goHelp,
  } = props;
  const drawer = (
    <DrawerWrapper>
      <DrawerHeader>
        <div>
          {authenticated ? (
            <FlexDiv></FlexDiv>
          ) : (
            <CursorDiv onClick={goSignIn}>
              <PersonIcon />
              <Typography>로그인/회원가입</Typography>
            </CursorDiv>
          )}
        </div>
        <IconButton onClick={handleDrawerToggle}>
          <ClearIcon />
        </IconButton>
      </DrawerHeader>
      <HiddenAnchor href={process.env.NEXT_PUBLIC_CUSTOMER_URL} target="_self">
        스마트 적산
      </HiddenAnchor>
      <DrawerList onClick={goCustomer}>
        <span>스마트 적산</span>
      </DrawerList>
      <HiddenAnchor href={process.env.NEXT_PUBLIC_PARTNER_URL} target="_self">
        파트너스
      </HiddenAnchor>
      <DrawerList onClick={goPartner}>
        <span>파트너스</span>
      </DrawerList>
      <DrawerList onClick={goHelp}>
        <span>고객센터</span>
      </DrawerList>
      {authenticated ? (
        <>
          <DrawerList onClick={goMyPage}>
            <span>마이페이지</span>
          </DrawerList>
        </>
      ) : null}
      {authenticated ? (
        <DrawerFooter onClick={goSignOut}>
          <ExitIcon />
          <Typography>로그아웃</Typography>
        </DrawerFooter>
      ) : null}
    </DrawerWrapper>
  );
  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={open}
        anchor={"right"}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "100vw",
            minWidth: 360,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
