import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkRemember } from "./checkRemember";
import { getUserId } from "supertokens-web-js/recipe/session";
import {
  emailPasswordSignIn,
  doesEmailExist,
  submitNewPassword,
} from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { client } from "context";
import { GetIdentityDataDocument, GetIdentityDataQuery } from "generated/types";
import { nanoid } from "nanoid";
// import { getIdentityAsync } from "utils";
import axios from "axios";
import { getIdentityAsync } from "views/utils";
// import { getIdentityAsync } from "utils";

if (typeof window !== "undefined") {
  checkRemember().then(() => {
    sessionStorage.setItem(
      "builderhub-session",
      JSON.stringify({ keep: nanoid(16) }),
    );
  });
}
export const getUserIdThunk = createAsyncThunk("auth/userId", getUserId);

export const emailPasswordSignInThunk = createAsyncThunk(
  "auth/emailPasswordSignIn",
  async ({ email, password }: { email: string; password: string }) => {
    const result = await emailPasswordSignIn({
      formFields: [
        { id: "email", value: email },
        { id: "password", value: password },
      ],
    });
    const { status } = result;
    if (status === "OK") return result.user;
    throw new Error(status);
  },
);

export const emailCheck = createAsyncThunk("auth/emailCheck", (email: string) =>
  doesEmailExist({
    email,
  }).then((response) => {
    return response.fetchResponse.json();
  }),
);

/* Creating a thunk that checks if the user is authenticated. */
export const checkAuth = createAsyncThunk("auth/check", async () => {
  const userId = await getUserId();
  return { userId };
  // const sessionExist = await doesSessionExist();
  // return { sessionExist };
});

export const signOutThunk = createAsyncThunk(
  "auth/signout",
  async () =>
    await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/signout`),
);

/* A thunk that changes the user's password. */
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ newPassword }: { newPassword: string }) =>
    submitNewPassword({ formFields: [{ id: "password", value: newPassword }] }),
);

export const getIdentityData = async (impUid: string) => {
  const { data } = await client.query<GetIdentityDataQuery>({
    query: GetIdentityDataDocument,
    variables: { impUid },
  });
  const { certification } = data;
  if (!certification) throw new Error("No identity");
  const { response, meta } = certification;
  if (!response) throw new Error("No response from identity service");
  const { name, gender, birthday, phone, unique_key } = response;
  if (!name || !phone || !unique_key) throw new Error("Not enough identity");
  const { isNotHaveUniqueKey, email } = meta;
  return {
    name,
    gender,
    birthday,
    phone,
    unique_key,
    isNotHaveUniqueKey,
    email,
  };
};

export const getIdentityPopup = createAsyncThunk(
  "auth/identity/popup",
  async (redirectUrl: string) => {
    const iamport = await getIdentityAsync(redirectUrl);
    const { imp_uid } = iamport;
    const result = await getIdentityData(imp_uid);
    return result;
  },
);

export const getIdentityRedir = createAsyncThunk(
  "auth/identity/redir",
  getIdentityData,
);
